/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../dist/wfbhsearch/wfbhsearch.ngfactory";
import * as i3 from "wfbhsearch";
import * as i4 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wfbhsearch-searchbox", [], null, null, null, i2.View_ɵi_0, i2.RenderType_ɵi)), i1.ɵdid(1, 4308992, null, 0, i3.ɵi, [i3.SearchService, i3.CoveoAnalyticsService], { useRouting: [0, "useRouting"], searchUrl: [1, "searchUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.searchUrl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i4.AppComponent, [i1.ElementRef, i3.SearchService, i3.CoveoAnalyticsService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
