<form class="search-form" (submit)="searchSubmit($event.target['search-keywords'].value, $event)">
    <div class="form-group form-group-lg ">
        <label class="sr-only" for="search-keywords">Search Keywords</label>
        <input autocomplete="off" type="search" class="form-control" [(ngModel)]="keywords" id="search-keywords"
            name="search-keywords" (keyup)="onKeywordsChanged($event)" (focus)="onSearchFocus()" (blur)="onSearchBlur()"
            placeholder="Provider’s name, specialty, condition, location...">
        <i id="search-running" *ngIf="searchOpRunning" class="fa fa-circle-o-notch fa-spin"></i>
        <a *ngIf="resetSearchVisible()" class="reset-search" title="Reset search" aria-label="Reset search"
            (click)="resetSearch()"><i class="fa fa-times"></i> <span class="sr-only">Reset search</span></a>
    </div>

    <button type="submit" class="btn btn-default btn-provider-search"><span class="desktop">Search</span><span
            class="mobile"><i class="fa fa-search"></i></span></button>
    <div *ngIf="suggest.length || showPopular" class="suggest-results">
        <ul role="listbox" *ngIf="suggest.length">
            <li wfbhsearch-suggest-result *ngFor="let result of suggest" [result]="result"
                (resultSelect)="selectSuggestion($event)">
            </li>
        </ul>
        <h3 class="popular-searches-title" *ngIf="showPopular && !suggest.length">Popular Searches</h3>
        <ul role="listbox" *ngIf="showPopular && !suggest.length">
            <li wfbhsearch-suggest-result *ngFor="let result of popularSearches" [title]="result.title"
                [uri]="searchUrl + result.uri" (resultSelect)="selectSuggestion($event)">
            </li>
        </ul>
    </div>
</form>