import { Component, ElementRef } from '@angular/core';
import { SearchService, CoveoAnalyticsService } from 'wfbhsearch';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'searchbox';
  searchUrl: string;
  constructor(elm: ElementRef, private search: SearchService, private analytics: CoveoAnalyticsService) {
    this.searchUrl = elm.nativeElement.getAttribute('searchUrl') || [location.protocol, '//', location.host, location.pathname].join('');
    this.search.setConfig('searchUrl', this.searchUrl);

    let searchIndex = elm.nativeElement.getAttribute('searchIndex');
    let accessKey = elm.nativeElement.getAttribute('accessKey');
    let pipeline = elm.nativeElement.getAttribute('pipeline');

    if (searchIndex) {
      this.search.setConfig('index', searchIndex, true);
    }
    if (accessKey) {
      this.search.setConfig('accessKey', accessKey, true);
      this.analytics.setAccessKey(accessKey);
    }
    if (pipeline) {
      this.search.setConfig('pipeline', pipeline, true);
    }
  }
}
