import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { WfbhSearchModule, SearchServiceConfig, SearchNavigatorService } from 'wfbhsearch';
import { SchedulingServiceConfig } from 'wfbhsearch/lib/scheduling-config';

const searchConfig: SearchServiceConfig = {
  accessKey: environment.accessKey,
  index: environment.index,
  searchUrl: '',
  pipeline: 'FindAProvider'
}
const schedulingConfig: SchedulingServiceConfig = {
  newPatientSchedulingUrl: environment.newPatientSchedulingUrl
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    WfbhSearchModule,
    WfbhSearchModule.forRoot(searchConfig, schedulingConfig)
  ],
  providers: [
    SearchNavigatorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
